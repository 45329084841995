import { Component } from '@angular/core';
import {Globals} from '../assets/shared/globals'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor( public globals: Globals ) { }

  title = this.globals.companyname;
}
