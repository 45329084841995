import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterproduct'
  })
  export class filterproduct implements PipeTransform {

    transform(items: any, vfilter?: any ): any {
      //console.log("helllo");
      if(!items || !Object.keys(vfilter).length){
        return items;
      }
      return items.filter(item => {
          return Object.keys(vfilter)
            .filter(_ => vfilter.hasOwnProperty(_))
            .every(key => {
              //console.log(key);
              if(!item[key]) return true; //matches undefined value
              let arrayValues = vfilter[key] as any[];
              //console.log(arrayValues);
              if(key == 'discounttype'){
                if(arrayValues.some(x => x =="70% or more")){
                  if(arrayValues.some(x => x =="30% and below")){
                    //arrayValues=[];
                    arrayValues.push('30% and below','70% or more');
                    //console.log("vv"+arrayValues);
                    return arrayValues.some(_ => _ === item[key]);
                  }
                  else{
                    //arrayValues=[];
                    arrayValues.push('70% or more');
                  //console.log("vv"+arrayValues);
                  return arrayValues.some(_ => _ === item[key]);
                  }
                }else if(arrayValues.some(x => x =="60% or more")){
                  if(arrayValues.some(x => x =="30% and below")){
                    //arrayValues=[];
                    arrayValues.push('30% and below','60% or more','70% or more');
                    //console.log("vv"+arrayValues);
                    return arrayValues.some(_ => _ === item[key]);
                  }
                  else{
                    //arrayValues=[];
                    arrayValues.push('60% or more','70% or more');
                  //console.log("vv"+arrayValues);
                  return arrayValues.some(_ => _ === item[key]);
                  }
                }else if(arrayValues.some(x => x =="50% or more")){
                  if(arrayValues.some(x => x =="30% and below")){
                    //arrayValues=[];
                    arrayValues.push('30% and below','50% or more','60% or more','70% or more');
                    //console.log("vv"+arrayValues);
                    return arrayValues.some(_ => _ === item[key]);
                  }
                  else{
                    //arrayValues=[];
                    arrayValues.push('50% or more','60% or more','70% or more');
                  //console.log("vv"+arrayValues);
                  return arrayValues.some(_ => _ === item[key]);
                  }
                }else if(arrayValues.some(x => x =="40% or more")){
                  if(arrayValues.some(x => x =="30% and below")){
                    //arrayValues=[];
                    arrayValues.push('30% and below','40% or more','50% or more','60% or more','70% or more');
                    //console.log("vv"+arrayValues);
                    return arrayValues.some(_ => _ === item[key]);
                  }
                  else{
                    //arrayValues=[];
                    arrayValues.push('40% or more','50% or more','60% or more','70% or more');
                  //console.log("vv"+arrayValues);
                  return arrayValues.some(_ => _ === item[key]);
                  }
                }
                else if(arrayValues.some(x => x =="30% or more")){
                  if(arrayValues.some(x => x =="30% and below")){
                    //arrayValues=[];
                    arrayValues.push('30% and below','30% or more','40% or more','50% or more','60% or more','70% or more');
                    //console.log("vv"+arrayValues);
                    return arrayValues.some(_ => _ === item[key]);
                  }
                  else{
                    //arrayValues=[];
                    arrayValues.push('30% or more','40% or more','50% or more','60% or more','70% or more');
                  //console.log("vv"+arrayValues);
                  return arrayValues.some(_ => _ === item[key]);
                  }
                }
                else{
                  if(arrayValues.some(x => x =="30% and below")){
                    //arrayValues=[];
                    arrayValues.push('30% and below');
                    //console.log("vv"+arrayValues);
                    return arrayValues.some(_ => _ === item[key]);
                  }
                  else{
                    //arrayValues=[];
                  //console.log("vv"+arrayValues);
                  return arrayValues.some(_ => _ === item[key]);
                  }
                }
              }

              if(key == 'customerratingtype'){
                if(arrayValues.some(x => x =="4★ & above")){
                  //arrayValues=[];
                  arrayValues.push('4★ & above');
                  //console.log("vv"+arrayValues);
                  return arrayValues.some(_ => _ === item[key]);
                }
                else if(arrayValues.some(x => x =="3★ & above")){
                  //arrayValues=[];
                  arrayValues.push('3★ & above','4★ & above');
                  //console.log("vv"+arrayValues);
                  return arrayValues.some(_ => _ === item[key]);
                }
                else if(arrayValues.some(x => x =="2★ & above")){
                  //arrayValues=[];
                  arrayValues.push('2★ & above','3★ & above','4★ & above');
                  //console.log("vv"+arrayValues);
                  return arrayValues.some(_ => _ === item[key]);
                }
                else if(arrayValues.some(x => x =="1★ & above")){
                  //arrayValues=[];
                  arrayValues.push('1★ & above','2★ & above','3★ & above','4★ & above');
                  //console.log("vv"+arrayValues);
                  return arrayValues.some(_ => _ === item[key]);
                }
                else{
                  //console.log("vv"+arrayValues);
                  return arrayValues.some(_ => _ === item[key]);
                }
              }

              return arrayValues.some(_ => _ === item[key]);
            });

      });
  }

  }