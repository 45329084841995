import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  user: firebase.User;

  constructor(private auth: AuthService, 
    public snackBar: MatSnackBar,
    private router: Router) { }
    openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
         duration: 2000,
         panelClass: ['snackbarcss'],
      });
      }
  ngOnInit() {
    // this.auth.getUserState()
    //   .subscribe( user => {
    //     this.user = user;
    //   })
  }

  logout() {
    // this.auth.logout();
  }

  verifyemail() {
    // this.auth.verifyEmail()
  }

}
