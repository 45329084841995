import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Router, ActivatedRoute } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';

declare function menucall() : any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  currentcustid: string;
  currentcustname: string;
  loadcart: any;
  showmenu: boolean =false; 
  submenuentered: boolean =false;
  loadcartqntycount: number;
  loadwishlistcount: number;

  addorreplace2(array, item){
    const i=array.findIndex(_item => _item.prdid===item.prdid);
    if(i>-1) array[i] =item;
    else array.push(item);
   }

  constructor(public snackBar: MatSnackBar, public afAuth: AngularFireAuth, private afs: AngularFirestore, private http:HttpClient, public router: Router) {
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
       panelClass: ['snackbarcss'],
    });
    }
  menumouseentercall(){
      this.showmenu=true;
  }
  menumouseleavecall(){
    if(this.submenuentered==false){
      this.showmenu=false;
  }
  }
  submenumouseentercall(){
    this.submenuentered=true;
    this.showmenu=true;
  }
  submenumouseleavecall(){
    this.submenuentered=false;

    this.showmenu=false;
  }

  ngOnInit() {
    this.afAuth.authState.subscribe(user=>{
      if(user){
      this.currentcustid=firebase.auth().currentUser.uid;
      // this.currentcustname=firebase.auth().currentUser.displayName;
      this.currentcustname=(firebase.auth().currentUser.displayName !=null) ? firebase.auth().currentUser.displayName : 'MY ACCOUNT';

     this.afs.collection('Carts',ref => ref.where("uid", "==" ,this.currentcustid)).snapshotChanges()      
      .pipe(map(actions => actions.map(a => a.payload.doc.data()))
      ).subscribe((posts) => {
    this.loadcart =[];
this.afs.collection("Carts", ref => ref.where("uid", "==" ,this.currentcustid)).get().toPromise().then(querySnapshot => {
  if (!querySnapshot.empty) {
    querySnapshot.forEach(doc => {
      if(doc.data().iscart==true){
      this.addorreplace2(this.loadcart,({'prdid':doc.data().pid, 'qnty':doc.data().qnty}));
      console.log(this.loadcart)
      }
    })
    this.loadcartqntycount= this.loadcart.reduce((subtotal, item) => subtotal + item.qnty,0);
  }
}).then(()=>{
  this.loadwishlistcount=0;
  this.afs.collection("users", ref => ref.where("uid", "==" ,this.currentcustid)).get().toPromise().then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        this.loadwishlistcount=(doc.data().wishlist) ? doc.data().wishlist.length : 0;
      })
    }
  })
})
})
      }
    })
  }

  signout(){
    firebase.auth().signOut().then(()=>{
      this.router.navigate(
        ['sign-in']);
    })
  }
}
